import { Expose, Transform } from 'class-transformer';
import {
    IsNumber, IsString,
} from 'class-validator';

export default class LocationAvailabilityDocumentModel {
    @Expose()
    // eslint-disable-next-line no-underscore-dangle
    @Transform((_, plain) => Number(plain._id))
    @IsString()
    id!: number;

    @Expose()
    @Transform((_, plain) => plain.fn_pick_up_location_code)
    @IsString()
    locationCode!: string;

    @Expose()
    @IsNumber()
    month!: string;

    @Expose()
    @IsString()
    pos!: string;

    @Expose()
    @IsNumber()
    year!: string;

    @Expose()
    @IsNumber()
    @Transform((_, plain) => plain.data_source || 'brand')
    dataSource!: string;

    @Expose()
    @Transform((_, plain) => plain.pickup_dates)
    pickupDates!: {
        [day: string] : {
            [provider: string]: {
                [lok: string]: boolean
            }
        }
    };
}
