import { IAllowedLors } from '../models/cars-filters.model';

/**
 *
 * @param options.lorsToFilter input list of lort to be filtered
 * @param options.allowedLORs object describes lors restrictions
 * @param options.pos (optional) POS by which the list should be filtered
 * @returns The filterd list of lors
 */
const filterLorsByAllowance = <T extends string | number>(
    options: { lorsToFilter: T[], locationId: string, allowedLORs: IAllowedLors, pos?: string},
): T[] => {
    const { lorsToFilter, allowedLORs, pos, locationId } = options;

    const isNoRestrictions = !Object.keys(allowedLORs)?.length;

    const whitelistByAnyPOS = allowedLORs.all;
    const whitelistByPOS = (pos && allowedLORs[pos]) || whitelistByAnyPOS;
    const whitelistByLocation = whitelistByPOS?.[locationId] || whitelistByPOS?.all;

    if (isNoRestrictions || !whitelistByPOS || !whitelistByLocation?.length) {
        return lorsToFilter;
    }

    return lorsToFilter.filter(lor => whitelistByLocation.includes(+lor));
};

export default filterLorsByAllowance;
