export default class LocationAvailabilitySettingsModel {
    providers: string[] | null = null;
    dataSources: string[] | null = null;
    providersPreserve: boolean = false;
    pickUpCityCodes: string[] | null = null;
    lor: number | null = null;
    pos: string | null = null;
    country: string | null = null;
    isClosedOnly : boolean = false;
    showAll: boolean = false;
    allPickUpCityCodes: string[] | null = null;
}
