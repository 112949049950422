import { Inject, injectable } from 'inversify-props';
import LocationAvailabilityDocumentModel from '@/modules/cars/modules/location-availability/models/location-availability-document.model';
import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import LocationAvailabilityApiService, { LocationAvailabilityApiServiceS }
    from '@/modules/cars/modules/location-availability/location-availability-api.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import LocationAvailabilityFiltersService, { LocationAvailabilityFiltersServiceS }
    from '@/modules/cars/modules/location-availability/location-availability-filters.service';
import _ from 'lodash';
import LocationAvailabilityStore from './store/location-availability.store';
import CarsSharedService, { CarsSharedServiceS } from '../../cars-shared.service';

export const LocationAvailabilityServiceS = Symbol.for('LocationAvailabilityServiceS');
@injectable(LocationAvailabilityServiceS as unknown as string)
export default class LocationAvailabilityService {
    @Inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @Inject(LocationAvailabilityApiServiceS) private locationAvailabilityApiService!: LocationAvailabilityApiService;
    @Inject(LocationAvailabilityFiltersServiceS) private lAvailabilityFiltersService!: LocationAvailabilityFiltersService;
    @Inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @Inject(HelperServiceS) private helperService!: HelperService;
    @Inject(CarsSharedServiceS) private carsSharedService!: CarsSharedService;
    @Inject(UserServiceS) private userService!: UserService;

    readonly storeState: LocationAvailabilityStore = this.storeFacade.getState('LocationAvailabilityStore');

    constructor() {
        this.storeFacade.watch(() => [
            this.storeState.filtersReady,
        ], this.init.bind(this));
    }

    init() {
        // Note: changes of country and pick-up locations are watched in LocationAvailabilityFiltersService because we need to update filters first.
        // Once filters are updated, we change store's allPickUpCityCodes which are watched below.
        this.storeFacade.watch(() => [
            this.documentFiltersService.month,
            // allPickUpCityCodes are changed on country or pick-up location change after POS/locations were updated
            this.storeState.settings.allPickUpCityCodes,
            this.storeState.settings.pos,
            this.storeState.filtersReady,
        ], this.resetLoading.bind(this));

        this.storeFacade.watch(() => [
            this.storeState.settings.lor,
        ], this.calculateClosedAndUnAvailableDays.bind(this));
    }

    resetLoading() {
        if (this.storeState.filtersReady && !this.storeState.loading.isLoading()) {
            this.storeState.loading.reset();
        }
    }

    async loadData(): Promise<boolean> {
        const { settings } = this.documentFiltersService.storeState;
        const locationAvailabilitySettings = this.storeState.settings;
        const { pos, allPickUpCityCodes } = locationAvailabilitySettings;
        const { locationAvailabilityPos } = this.carsSharedService;
        const { allowedVendorsPerCountry } = this.carsSharedService.filters;
        const { dataSources } = this.carsSharedService.filters;
        const { chainName } = this.userService;

        if (!pos || !allPickUpCityCodes || !allPickUpCityCodes.length) {
            return false;
        }
        const apiData = { ...locationAvailabilitySettings };
        const data = await this.locationAvailabilityApiService.getNotAvailableDocs(settings, apiData);

        const documents = {} as {
            [locationId: string] : { [dataSource: string]: LocationAvailabilityDocumentModel }
        };

        data.forEach((location: LocationAvailabilityDocumentModel) => {
            if (!documents[location.locationCode]) {
                documents[location.locationCode] = { };
            }
            if (!documents[location.locationCode][location.dataSource]) {
                documents[location.locationCode][location.dataSource] = { } as any;
            }
            documents[location.locationCode][location.dataSource] = location;
        });
        let country: string | null = '';
        if (locationAvailabilityPos) {
            country = this.getCountryByPOS(locationAvailabilityPos);
        }

        const dataSourcesMap: { [dataSource: string]: string } = { };
        dataSources?.forEach(element => {
            const lowerCaseDataSource = element.toLowerCase();
            dataSourcesMap[lowerCaseDataSource] = element;
        });

        Object.keys(documents).forEach(locId => {
            Object.keys(documents[locId]).forEach(dataSource => {
                Object.keys(documents[locId][dataSource].pickupDates || { }).forEach(day => {
                    const currentProvidersObject = documents[locId][dataSource].pickupDates[day];
                    const providerList = Object.keys(currentProvidersObject) || [];
                    providerList.forEach(provider => {
                        if (
                            !['all', chainName?.toLocaleLowerCase()].includes(provider.toLowerCase())
                            && country
                            && allowedVendorsPerCountry?.[country]?.[dataSourcesMap?.[dataSource]]
                            && !allowedVendorsPerCountry?.[country]?.[dataSourcesMap?.[dataSource]].includes(provider)
                        ) {
                            delete documents[locId][dataSource].pickupDates[day][provider];
                        }
                    });
                });
            });
        });

        this.storeState.documents = documents;
        this.calculateClosedAndUnAvailableDays();
        return true;
    }

    getCountryByPOS(pos: string) {
        const { countryCodes } = this.carsSharedService.filters;
        if (!countryCodes) {
            return null;
        }
        return countryCodes.filter(countryCode => countryCode.code === pos)?.[0]?.name || '';
    }

    get documents() {
        if (this.storeState.filtersReady) {
            this.helperService.dynamicLoading(this.storeState.loading, this.loadData.bind(this));
        }

        return this.storeState.documents;
    }

    get closedDays() {
        return this.storeState.closedDays;
    }

    get availableDays() {
        return this.storeState.availableDays;
    }

    get unAvailableDays() {
        return this.storeState.unAvailableDays;
    }

    get providerAvailablePerDataSource() {
        return this.storeState.providerAvailablePerDataSource;
    }

    calculateClosedAndUnAvailableDays() {
        const closedDays = { } as {
            [location: string]: {
                [dataSource: string]: {
                    [provider: string]: number
                }
            }
        };

        const unAvailableDays = { } as {
            [location: string]: {
                [dataSource: string]: {
                    [provider: string]: number
                }
            }
        };

        const availableDays = { } as {
            [location: string]: {
                [dataSource: string]: {
                    [provider: string]: number
                }
            }
        };

        const providerAvailablePerDataSource = { } as {
            [location: string]: {
                [dataSource: string]: {
                    [provider: string]: number
                }
            }
        };

        const { providersList } = this.lAvailabilityFiltersService;
        const documents = this.documents || {};
        const allProvidersList = [...providersList, 'all'];
        Object.keys(documents).forEach(locationId => {
            closedDays[locationId] = {} as any;
            unAvailableDays[locationId] = {} as any;
            availableDays[locationId] = {} as any;
            providerAvailablePerDataSource[locationId] = {} as any;
            Object.keys(documents[locationId]).forEach(dataSource => {
                const doc = documents[locationId][dataSource];
                closedDays[locationId][dataSource] = {} as any;
                unAvailableDays[locationId][dataSource] = {} as any;
                availableDays[locationId][dataSource] = {} as any;
                providerAvailablePerDataSource[locationId][dataSource] = {} as any;
                const pickupDays = doc.pickupDates;
                if (pickupDays) {
                    this.documentFiltersService.days.forEach(day => {
                        const key = `${locationId}.${dataSource}.pickupDates.${day}`;
                        Object.keys(_.get(this.documents, key, { })).forEach(inProvider => {
                            providerAvailablePerDataSource[locationId][dataSource][inProvider] = 1;
                        });
                        allProvidersList.forEach(provider => {
                            if (!closedDays[locationId][dataSource][provider]) {
                                closedDays[locationId][dataSource][provider] = 0;
                            }
                            if (!unAvailableDays[locationId][dataSource][provider]) {
                                unAvailableDays[locationId][dataSource][provider] = 0;
                            }
                            if (!availableDays[locationId][dataSource][provider]) {
                                availableDays[locationId][dataSource][provider] = 0;
                            }
                            const closedDaysStatus = this.getLocationsAvailability(locationId, dataSource, provider, String(day));
                            const closedDaysStatusAll = this.getLocationsAvailability(locationId, dataSource, 'all', String(day));
                            if (closedDaysStatus === true) {
                                closedDays[locationId][dataSource][provider] += 1;
                            }
                            if (closedDaysStatus === false) {
                                availableDays[locationId][dataSource][provider] += 1;
                            }
                            if (closedDaysStatus === null) {
                                if (closedDaysStatusAll === false) {
                                    closedDays[locationId][dataSource][provider] += 1;
                                } else if (closedDaysStatusAll === true) {
                                    availableDays[locationId][dataSource][provider] += 1;
                                } else {
                                    unAvailableDays[locationId][dataSource][provider] += 1;
                                }
                            }
                        });
                    });
                }
            });
        });
        this.storeState.providerAvailablePerDataSource = providerAvailablePerDataSource;
        this.storeState.availableDays = availableDays;
        this.storeState.closedDays = closedDays;
        this.storeState.unAvailableDays = unAvailableDays;
    }

    getLocationsAvailability(locationId: string, dataSource: string, provider: string, day: string) {
        const { lor } = this.storeState.settings;
        const noDataStatus = null;
        const key = `${locationId}.${dataSource}.pickupDates.${day}.${provider}.${lor}`;
        return _.get(this.documents, key, noDataStatus) as boolean | null;
    }

    isDataSourceAvailable(locationId: string, dataSource: string) {
        return this.documents && this.documents[locationId][dataSource];
    }
}
