import { Module, VuexModule } from 'vuex-module-decorators';
import LoadingModel from '@/modules/common/models/loading.model';
import LocationAvailabilityDocumentModel from '@/modules/cars/modules/location-availability/models/location-availability-document.model';
import LocationAvailabilitySettingsModel from '../models/location-availability-settings.model';

@Module
export default class LocationAvailabilityStore extends VuexModule {
    filtersReady: boolean = false;
    loading: LoadingModel = new LoadingModel();
    settings: LocationAvailabilitySettingsModel = new LocationAvailabilitySettingsModel();
    documents: {
        [locationId: string] : { [dataSource: string]: LocationAvailabilityDocumentModel }
    } | null = null;
    closedDays: {
        [location: string]: {
            [dataSource: string]: {
                [provider: string]: number
            }
        }
    } = {};
    availableDays: {
        [location: string]: {
            [dataSource: string]: {
                [provider: string]: number
            }
        }
    } = {};
    providerAvailablePerDataSource: {
        [location: string]: {
            [dataSource: string]: {
                [provider: string]: number
            }
        }
    } = {};
    unAvailableDays: {
        [location: string]: {
            [dataSource: string]: {
                [provider: string]: number
            }
        }
    } = {};
    locationQuery: string | null = null;
    currentPickupLocations: {name: string, value: string}[] = [];
}
