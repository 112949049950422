import { Inject, injectable } from 'inversify-props';
import { flatten, get } from 'lodash';
import Stateable from '@/modules/common/interfaces/stateable.interface';
import GRAPH_COLORS, { DEFAULT_COLOR } from '@/modules/common/constants/default-graph-colors.constant';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';
import Day from '@/modules/common/types/day.type';
import GRAPH_TYPE from '@/modules/cars/modules/fleet/constants/graph-types.constant';
import FleetStore from '@/modules/cars/modules/fleet/store/fleet.store';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import FleetApiService, { FleetApiServiceS } from '@/modules/cars/modules/fleet/fleet-api.service';
import CarsSharedService, { CarsSharedServiceS } from '@/modules/cars/cars-shared.service';
import PROVIDER_COLORS from '@/modules/common/constants/providers.colors.constant';
import StoreFacade, { StoreFacadeS } from '../../../common/services/store-facade';
import { COUNTRIES_ANY, MILEAGE_ANY, PAYMENT_TERMS_ANY, POS_ANY, TRANSMISSION_ANY } from '../../constants/car-filter-types.constant';
import CarsService, { CarsServiceS } from '../../cars.service';

export const FleetServiceS = Symbol.for('FleetServiceS');
@injectable(FleetServiceS as unknown as string)
export default class FleetService implements Stateable {
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @Inject(FleetApiServiceS) private fleetApiService!: FleetApiService;
    @Inject(HelperServiceS) private helperService!: HelperService;
    @Inject(HotelsServiceS) private hotelsService!: HotelsService;
    @Inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @Inject(CarsSharedServiceS) private carsSharedService!: CarsSharedService;
    @Inject(CarsServiceS) private carsService!: CarsService;

    readonly storeState: FleetStore = this.storeFacade.getState('FleetStore');

    constructor() {
        this.storeFacade.watch(
            () => [
                this.documentFiltersService.storeState.settings.year,
                this.documentFiltersService.storeState.settings.month,
                this.storeState.settings.lor,
                this.storeState.settings.pos,
            ],
            this.resetFleetLoading.bind(this, false),
        );

        this.storeFacade.watch(
            () => [
                this.storeState.settings.pickUpCityCode,
                this.storeState.settings.country,
                this.storeState.filtersReady,
            ],
            this.resetFleetLoading.bind(this, true),
        );
    }

    resetFleetLoading(validateLoading: boolean) {
        const resetCondition = validateLoading ? this.storeState.filtersReady && !this.storeState.loading.isLoading() : this.storeState.filtersReady;
        if (resetCondition) {
            this.storeState.loading.reset();
        }
    }

    switchGraphType(provider: string) {
        const graphType = this.getGraphType(provider);
        this.storeState.graphType[provider] = graphType === GRAPH_TYPE.RANGE ? GRAPH_TYPE.BROKERS : GRAPH_TYPE.RANGE;
        this.storeState.graphType = { ...this.storeState.graphType };
    }

    get fleetDocument() {
        if (this.storeState.filtersReady) {
            this.helperService.dynamicLoading(this.storeState.loading, this.loadData.bind(this));
        }
        return this.storeState.document;
    }

    async loadData() {
        const { year, month } = this.documentFiltersService;
        const { lor, pos } = this.storeState.settings;
        if (!this.userService.isCarUser) {
            return false;
        }

        if (!this.dataSourcesFromFilter || !year || month === null) {
            return false;
        }

        if (!lor || !pos || !this.availablePos || !this.availablePos.includes(pos)) {
            return false;
        }
        const chain = this.carsSharedService.currentChain;
        this.storeState.document = await this.fleetApiService.getFleet(this.storeState.settings, this.dataSourcesFromFilter, year, month, chain);

        return true;
    }

    get dataSourcesFromFilter() {
        return this.storeState.settings.dataSources;
    }

    get dataSources() {
        if (!this.fleetDocument || !this.fleetDocument.providers) {
            return [];
        }
        return Object.keys(this.fleetDocument.providers);
    }

    isExistSource(sources: string) {
        if (!this.dataSources) {
            return false;
        }
        return this.dataSources.includes(sources);
    }

    getPickUpDateByProvider(provider: string) {
        if (!this.storeState.document || !this.storeState.document.providers || !this.storeState.document.providers[provider]) {
            return false;
        }
        return this.storeState.document.providers[provider].pickupDates;
    }

    carCategoriesByDay(day: Day, provider?: string) {
        if (!this.storeState.document || !this.storeState.document.providers) {
            return [];
        }

        let categories: string[] = [];
        const pickUpDate = this.getPickUpDateByProvider(provider as string);

        if (!pickUpDate) {
            return [];
        }

        const currentData = pickUpDate[day];

        if (!currentData) {
            return [];
        }

        Object.keys(currentData).forEach(vendor => {
            categories = categories.concat(Object.keys(currentData[vendor]));
        });

        return [...new Set(categories)].filter(item => this.carClasses.includes(item));
    }

    getGraphType(provider: string) {
        if (!this.storeState.graphType[provider]) {
            return GRAPH_TYPE.RANGE;
        }
        return this.storeState.graphType[provider];
    }

    getNumberCarByBroker(day: Day, broker: string) {
        if (!this.dataSources) {
            return 0;
        }

        if (!this.dataSource) {
            return 0;
        }

        let total = 0;
        const count = this.getNumberCurrentBroker(day, this.dataSource, broker);
        total += count;

        return total;
    }

    getNumberCurrentBroker(day: Day, source: string, broker: string) {
        const sourceData = this.getPickUpDateByProvider(source);

        if (!sourceData) {
            return 0;
        }
        const pickupDate = sourceData[day];
        if (!pickupDate) {
            return 0;
        }
        const company = pickupDate[broker];
        if (!company) {
            return 0;
        }

        let count = 0;
        Object.keys(company).forEach(carClass => {
            if (this.carClasses.includes(carClass)) {
                const pickupDateCarClass = company[carClass];
                const currentTransmission = (this.transmission === TRANSMISSION_ANY)
                    ? Object.keys(pickupDateCarClass || []) : [this.transmission];

                currentTransmission.forEach(trans => {
                    const pickupDateTransmission = pickupDateCarClass[trans];
                    const currentMileagePolicy = (this.mileage === MILEAGE_ANY)
                        ? Object.keys(pickupDateTransmission || []) : [this.mileage];

                    currentMileagePolicy.forEach(mileagePolicy => {
                        if (pickupDateTransmission) {
                            const pickupDateMileagePolicy = pickupDateTransmission[mileagePolicy];
                            const currentPaymentTerms = (this.paymentTerms === PAYMENT_TERMS_ANY)
                                ? Object.keys(pickupDateMileagePolicy || []) : [this.paymentTerms];

                            currentPaymentTerms.forEach(paymentTerms => {
                                if (pickupDateMileagePolicy) {
                                    const pickupDatePaymentTerms = pickupDateMileagePolicy[paymentTerms];
                                    if (pickupDatePaymentTerms) {
                                        count += pickupDatePaymentTerms;
                                    }
                                }
                            });
                        }
                    });
                });
            }
        });

        return count;
    }

    getNumberCar(day: Day, source: any) {
        const sourceData = this.getPickUpDateByProvider(source);

        if (!sourceData) {
            return 0;
        }

        const pickupDate = sourceData[`${day}`];

        if (!pickupDate) {
            return 0;
        }

        const { currentCompany } = this.userService;

        if (!currentCompany) {
            return 0;
        }

        let count = 0;
        const pickupDateCompany = pickupDate[currentCompany];
        Object.keys(pickupDateCompany).forEach(carClass => {
            if (this.carClasses.includes(carClass)) {
                const pickupDateCarClass = pickupDateCompany[carClass];
                const currentTransmission = (this.transmission === TRANSMISSION_ANY)
                    ? Object.keys(pickupDateCarClass || []) : [this.transmission];

                currentTransmission.forEach(trans => {
                    const pickupDateTransmission = pickupDateCarClass[trans];
                    const currentMileagePolicy = (this.mileage === MILEAGE_ANY)
                        ? Object.keys(pickupDateTransmission || []) : [this.mileage];

                    currentMileagePolicy.forEach(mileagePolicy => {
                        if (pickupDateTransmission) {
                            const pickupDateMileagePolicy = pickupDateTransmission[mileagePolicy];
                            const currentPaymentTerms = (this.paymentTerms === PAYMENT_TERMS_ANY)
                                ? Object.keys(pickupDateMileagePolicy || []) : [this.paymentTerms];

                            currentPaymentTerms.forEach(paymentTerms => {
                                if (pickupDateMileagePolicy) {
                                    const pickupDatePaymentTerms = pickupDateMileagePolicy[paymentTerms];
                                    if (pickupDatePaymentTerms) {
                                        count += pickupDatePaymentTerms;
                                    }
                                }
                            });
                        }
                    });
                });
            }
        });

        return count;
    }

    isNa(day: Day, source: any) {
        const sourceData = this.getPickUpDateByProvider(source);

        if (!sourceData) {
            return 0;
        }

        const pickupDate = sourceData[`${day}`];

        if (!pickupDate) {
            return 0;
        }

        const competitorsArray = this.getCompetitorsArrayFromGeneralSetting(source);

        let count = 0;
        Object.keys(pickupDate).forEach(company => {
            if (competitorsArray.includes(company)) {
                const pickupDateCompany = pickupDate[company];
                Object.keys(pickupDateCompany).forEach(carClass => {
                    if (this.carClasses.includes(carClass)) {
                        const pickupDateCarClass = pickupDateCompany[carClass];
                        const currentTransmission = (this.transmission === TRANSMISSION_ANY)
                            ? Object.keys(pickupDateCarClass || []) : [this.transmission];

                        currentTransmission.forEach(trans => {
                            const pickupDateTransmission = pickupDateCarClass[trans];
                            const currentMileagePolicy = (this.mileage === MILEAGE_ANY)
                                ? Object.keys(pickupDateTransmission || []) : [this.mileage];

                            currentMileagePolicy.forEach(mileagePolicy => {
                                if (pickupDateTransmission) {
                                    const pickupDateMileagePolicy = pickupDateTransmission[mileagePolicy];
                                    const currentPaymentTerms = (this.paymentTerms === PAYMENT_TERMS_ANY)
                                        ? Object.keys(pickupDateMileagePolicy || []) : [this.paymentTerms];

                                    currentPaymentTerms.forEach(paymentTerms => {
                                        if (pickupDateMileagePolicy) {
                                            const pickupDatePaymentTerms = pickupDateMileagePolicy[paymentTerms];
                                            if (pickupDatePaymentTerms) {
                                                count += pickupDatePaymentTerms;
                                            }
                                        }
                                    });
                                }
                            });
                        });
                    }
                });
            }
        });

        return count;
    }

    isOutOfRange(day: Day, source: any) {
        if (!this.fleetDocument || !this.fleetDocument.providers) {
            return true;
        }
        const sourceData = this.getPickUpDateByProvider(source);

        if (!sourceData) {
            return true;
        }

        const pickupDate = sourceData[`${day}`];

        if (!pickupDate) {
            return true;
        }

        return false;
    }

    isSoldOut(day: Day, source: any) {
        if (!this.fleetDocument || !this.fleetDocument.providers) {
            return true;
        }
        const data = this.getPickUpDateByProvider(source);
        if (!data || !data[`${day}`]) {
            return true;
        }
        const jsonData = JSON.stringify(data[`${day}`]);
        let pickupDate = JSON.parse(jsonData);

        pickupDate = this.filterCompetitors(pickupDate, source);

        Object.keys(pickupDate).forEach(company => {
            this.filterCarClasses(pickupDate[company]);
            const pickupDateCompany = pickupDate[company];
            Object.keys(pickupDateCompany).forEach(carClass => {
                const pickupDateCarClass = pickupDateCompany[carClass];
                this.filterTransmission(pickupDateCarClass);
                Object.keys(pickupDateCarClass).forEach(transmission => {
                    const mileageData = pickupDateCarClass[transmission];
                    this.filterMileage(mileageData);
                    Object.keys(mileageData).forEach(mileage => {
                        const paymentsData = mileageData[mileage];
                        this.filterPayment(paymentsData);
                        if (Object.keys(paymentsData).length === 0) {
                            delete mileageData[mileage];
                        }
                    });
                    if (Object.keys(mileageData).length === 0) {
                        delete pickupDateCarClass[transmission];
                    }
                });
                if (Object.keys(pickupDateCarClass).length === 0) {
                    delete pickupDateCompany[carClass];
                }
            });
            if (Object.keys(pickupDateCompany).length === 0) {
                delete pickupDate[company];
            }
        });

        const company = this.userService.currentCompany;
        if (!company) {
            return true;
        }

        if (!pickupDate[company]) {
            return true;
        }

        if (Object.keys(pickupDate[company]).length === 0) {
            return true;
        }

        return false;
    }

    filterCompetitors(data: any, provider: string) {
        const competitorsData = data;
        const competitorsArray = this.getCompetitorsArrayFromGeneralSetting(provider);
        Object.keys(competitorsData).forEach(company => {
            if (!competitorsArray.includes(company)) {
                delete competitorsData[company];
            }
        });

        return competitorsData;
    }

    filterCarClasses(data: any) {
        const classesData = data;
        Object.keys(classesData).forEach(carClasses => {
            if (!this.carClasses.includes(carClasses)) {
                delete classesData[carClasses];
            }
        });

        return classesData;
    }

    filterTransmission(data: any) {
        const transmissionData = data;
        Object.keys(transmissionData).forEach(transmission => {
            if (this.transmission !== transmission && this.transmission !== TRANSMISSION_ANY) {
                delete transmissionData[transmission];
            }
        });

        return transmissionData;
    }

    filterMileage(data: any) {
        const mileageData = data;
        Object.keys(mileageData).forEach(mileage => {
            if (this.mileage !== mileage && this.mileage !== MILEAGE_ANY) {
                delete mileageData[mileage];
            }
        });

        return mileageData;
    }

    filterPayment(data: any) {
        const paymentData = data;
        Object.keys(paymentData).forEach(payment => {
            if (this.paymentTerms !== payment && this.paymentTerms !== PAYMENT_TERMS_ANY) {
                delete paymentData[payment];
            }
        });

        return paymentData;
    }

    getCountByBroker(provider: string, broker: string) {
        const data: any = [];
        this.documentFiltersService.days.forEach(item => {
            const count = this.carsBrokersCountDay(item, provider, broker);
            data.push(count);
        });

        return data;
    }

    async triggerScan(day: Day | undefined) {
        return '';
    }

    getTotal(broker: string) {
        if (!this.dataSource) {
            return 0;
        }

        let total = 0;
        const countArray = this.getCountByBroker(this.dataSource, broker);
        const reducer = (accumulator: number, currentValue: number) => accumulator + currentValue;
        total += countArray.reduce(reducer);
        return total;
    }

    get brokers() {
        return this.dataSources;
    }

    get companies() {
        if (!this.storeState.document || !this.storeState.document.providers) {
            return [];
        }

        let allCompanies: string[] = [];
        this.dataSources.forEach((source: string) => {
            const companies = this.getCompaniesByProvider(source);
            allCompanies = [...companies, ...allCompanies];
        });

        return [...new Set(allCompanies)];
    }

    getCompaniesByProvider(provider: string) {
        const dates = this.getPickUpDateByProvider(provider);

        if (!dates) {
            return [];
        }
        let companies: string[] = [];
        Object.entries(dates).forEach(([day, index]) => {
            companies = [...Object.keys(dates[day]), ...companies];
        });
        companies = [...new Set(companies)];
        return companies;
    }

    currentCompanyCount(day: Day, broker: string) {
        const { currentCompany } = this.userService;
        const brokerData = this.getPickUpDateByProvider(broker);

        if (!currentCompany) {
            return null;
        }

        if (!brokerData) {
            return null;
        }

        const pickDate = brokerData[day];

        if (!pickDate) {
            return null;
        }

        const currentCompanyData = pickDate[currentCompany];

        if (!currentCompanyData) {
            return null;
        }

        let count = 0;
        Object.keys(currentCompanyData).forEach(carClass => {
            if (this.carClasses.includes(carClass)) {
                const pickupDateCarClass = currentCompanyData[carClass];
                const currentTransmission = (this.transmission === TRANSMISSION_ANY)
                    ? Object.keys(pickupDateCarClass || []) : [this.transmission];

                currentTransmission.forEach(trans => {
                    const pickupDateTransmission = pickupDateCarClass[trans];
                    const currentMileagePolicy = (this.mileage === MILEAGE_ANY)
                        ? Object.keys(pickupDateTransmission || []) : [this.mileage];

                    currentMileagePolicy.forEach(mileagePolicy => {
                        if (pickupDateTransmission) {
                            const pickupDateMileagePolicy = pickupDateTransmission[mileagePolicy];
                            const currentPaymentTerms = (this.paymentTerms === PAYMENT_TERMS_ANY)
                                ? Object.keys(pickupDateMileagePolicy || []) : [this.paymentTerms];

                            currentPaymentTerms.forEach(paymentTerms => {
                                if (pickupDateMileagePolicy) {
                                    const pickupDatePaymentTerms = pickupDateMileagePolicy[paymentTerms];
                                    if (pickupDatePaymentTerms) {
                                        count += pickupDatePaymentTerms;
                                    }
                                }
                            });
                        }
                    });
                });
            }
        });

        return count;
    }

    carsCountDay(day: Day, broker: string): number[] | null {
        const brokerData = this.getPickUpDateByProvider(broker);

        if (!brokerData) {
            return null;
        }

        if (!brokerData[day]) {
            return null;
        }

        const pickDate = brokerData[day];

        if (!pickDate) {
            return null;
        }

        if (!pickDate) {
            return null;
        }

        const brokersData: any = {};
        const allowedCompanies = this.getCompetitorsArrayFromGeneralSetting(broker);
        Object.keys(pickDate).forEach(company => {
            if (allowedCompanies.includes(company)) {
                Object.keys(pickDate[company]).forEach((carClass: string) => {
                    brokersData[company] = brokersData[company] || 0;
                    if (this.carClasses.includes(carClass)) {
                        // @ts-ignore
                        const pickupDateCarClass = pickDate[company][carClass];
                        const currentTransmission = (this.transmission === TRANSMISSION_ANY)
                            ? Object.keys(pickupDateCarClass || []) : [this.transmission];

                        currentTransmission.forEach(trans => {
                            const pickupDateTransmission = pickupDateCarClass[trans];
                            const currentMileagePolicy = (this.mileage === MILEAGE_ANY)
                                ? Object.keys(pickupDateTransmission || []) : [this.mileage];

                            currentMileagePolicy.forEach(mileagePolicy => {
                                if (pickupDateTransmission) {
                                    const pickupDateMileagePolicy = pickupDateTransmission[mileagePolicy];
                                    const currentPaymentTerms = (this.paymentTerms === PAYMENT_TERMS_ANY)
                                        ? Object.keys(pickupDateMileagePolicy || []) : [this.paymentTerms];

                                    currentPaymentTerms.forEach(paymentTerms => {
                                        if (pickupDateMileagePolicy) {
                                            const pickupDatePaymentTerms = pickupDateMileagePolicy[paymentTerms];
                                            if (pickupDatePaymentTerms) {
                                                brokersData[company] += pickupDatePaymentTerms;
                                            }
                                        }
                                    });
                                }
                            });
                        });
                    }
                });
            }
        });
        return Object.values(brokersData);
    }

    carsBrokersCountDay(day: Day, provider?: string, broker?: string) {
        if (!this.storeState.document || !this.storeState.document.providers) {
            return null;
        }

        const brokerData = this.getPickUpDateByProvider(provider as string);

        if (!brokerData) {
            return null;
        }

        if (!brokerData[day]) {
            return null;
        }

        const pickDate = brokerData[day];

        if (!pickDate) {
            return null;
        }

        const company = pickDate[broker as string];

        if (!company) {
            return null;
        }

        let count = 0;
        Object.keys(company).forEach(carClass => {
            if (this.carClasses.includes(carClass)) {
                const pickupDateCarClass = company[carClass];
                const currentTransmission = (this.transmission === TRANSMISSION_ANY)
                    ? Object.keys(pickupDateCarClass || []) : [this.transmission];

                currentTransmission.forEach(trans => {
                    const pickupDateTransmission = pickupDateCarClass[trans];
                    const currentMileagePolicy = (this.mileage === MILEAGE_ANY)
                        ? Object.keys(pickupDateTransmission || []) : [this.mileage];

                    currentMileagePolicy.forEach(mileagePolicy => {
                        if (pickupDateTransmission) {
                            const pickupDateMileagePolicy = pickupDateTransmission[mileagePolicy];
                            const currentPaymentTerms = (this.paymentTerms === PAYMENT_TERMS_ANY)
                                ? Object.keys(pickupDateMileagePolicy || []) : [this.paymentTerms];

                            currentPaymentTerms.forEach(paymentTerms => {
                                if (pickupDateMileagePolicy) {
                                    const pickupDatePaymentTerms = pickupDateMileagePolicy[paymentTerms];
                                    if (pickupDatePaymentTerms) {
                                        count += pickupDatePaymentTerms;
                                    }
                                }
                            });
                        }
                    });
                });
            }
        });

        return count;
    }

    brokerMax(day: Day, broker: string) {
        const count = this.carsCountDay(day, broker);

        if (!count) {
            return null;
        }

        return Math.max(...count);
    }

    brokerMin(day: Day, broker: string) {
        const count = this.carsCountDay(day, broker);

        if (!count) {
            return null;
        }

        return Math.min(...count);
    }

    get currentBroker() {
        return this.userService.currentCompany;
    }

    get currentBrokerId() {
        return 1;
    }

    get finishScanDate() {
        return new Date();
    }

    getBrokersGraphColor(brokerName: string) {
        return PROVIDER_COLORS[brokerName] || DEFAULT_COLOR;
    }

    brokersDataByDay(day: Day, provider?: string) {
        const { currentCompany } = this.userService;
        if (!this.storeState.document || !this.storeState.document.providers || !currentCompany) {
            return [];
        }

        let companies = provider ? this.getCompaniesByProvider(provider) : this.companies;

        const competitorsArray = this.getCompetitorsArrayFromGeneralSetting(provider);
        companies = companies.filter(item => competitorsArray.includes(item));

        const brokersData: any = [];
        companies.forEach(item => {
            const classesData: Record<string, number> = this.carClassesData(item, day, provider);
            const total = Object.values(classesData).reduce((val: number, acc: number) => acc + val, 0);
            brokersData.push({
                ...classesData,
                brokerName: item,
                total,
                myBroker: item === this.userService.currentCompany,
            });
        });

        return brokersData;
    }

    carClassesData(broker: string, day: Day, provider?: string) {
        if (!this.storeState.document || !this.storeState.document.providers) {
            return {};
        }

        if (!provider) {
            return {};
        }

        const fleetData = this.getPickUpDateByProvider(provider);

        if (!fleetData) {
            return {};
        }

        let pickupDate = fleetData[`${day}`];

        if (!pickupDate) {
            return {};
        }

        const jsonData = JSON.stringify(pickupDate);
        pickupDate = JSON.parse(jsonData);

        pickupDate = this.filterCompetitors(pickupDate, provider);
        Object.keys(pickupDate).forEach(company => {
            this.filterCarClasses(pickupDate[company]);
            const pickupDateCompany = pickupDate[company];
            Object.keys(pickupDateCompany).forEach(carClass => {
                const pickupDateCarClass = pickupDateCompany[carClass];
                this.filterTransmission(pickupDateCarClass);
                Object.keys(pickupDateCarClass).forEach(transmission => {
                    const mileageData = pickupDateCarClass[transmission];
                    this.filterMileage(mileageData);
                    Object.keys(mileageData).forEach(mileage => {
                        const paymentsData = mileageData[mileage];
                        this.filterPayment(paymentsData);
                        if (Object.keys(paymentsData).length === 0) {
                            delete mileageData[mileage];
                        }
                    });
                    if (Object.keys(mileageData).length === 0) {
                        delete pickupDateCarClass[transmission];
                    }
                });
                if (Object.keys(pickupDateCarClass).length === 0) {
                    delete pickupDateCompany[carClass];
                }
            });
            if (Object.keys(pickupDateCompany).length === 0) {
                delete pickupDate[company];
            }
        });
        const carCategoriesByDay = this.carCategoriesByDay(day, provider);
        const categoriesData: any = {};
        carCategoriesByDay.forEach(item => {
            const pickupData = pickupDate;
            if (pickupData) {
                const company = pickupData[broker];
                if (company) {
                    const classData = company[item];
                    if (classData) {
                        categoriesData[item] = this.getCountByClass(classData);
                    }
                }
            }
        });
        return categoriesData;
    }

    getCountByClass(classData: any) {
        let count = 0;
        Object.entries(classData).forEach(([transmission, item]) => {
            Object.entries(classData[transmission]).forEach(([paymentType, data]) => {
                Object.entries(classData[transmission][paymentType]).forEach(([type, paymentData]) => {
                    // @ts-ignore
                    count += paymentData;
                });
            });
        });
        return count;
    }

    get country() {
        return this.storeState.settings.country || COUNTRIES_ANY;
    }

    get pickupLocation() {
        return this.storeState.settings.pickUpCityCode || '';
    }

    get lor() {
        return this.storeState.settings.lor || '';
    }

    get pos() {
        return this.storeState.settings.pos || POS_ANY;
    }

    get transmission() {
        return this.storeState.settings.transmission || TRANSMISSION_ANY;
    }

    get carClasses() {
        return this.storeState.settings.carClasses || [];
    }

    get competitors() {
        const unselectedCompetitors = this.carsSharedService.getUnselectedCompetitors();

        return this.storeState.settings.competitors?.filter(item => !unselectedCompetitors.includes(item)) || [];
    }

    get mileage() {
        return this.storeState.settings.mileage || MILEAGE_ANY;
    }

    get paymentTerms() {
        return this.storeState.settings.paymentTerms || PAYMENT_TERMS_ANY;
    }

    get dataSource() {
        return this.storeState.settings.dataSource;
    }

    get availablePos() {
        const carSettings = this.storeState.settings;
        const { availability } = this.carsSharedService.filters;

        if (!availability) {
            return null;
        }

        const currentDoc = availability.find(doc => doc.id === carSettings.pickUpCityCode);

        if (!currentDoc || !carSettings.dataSources) {
            return null;
        }

        const availableDataSource = carSettings.dataSources.map((dataSource: string) => Object.keys(get(currentDoc?.path, dataSource, {})));
        const availablePos = Array.from(new Set(flatten(availableDataSource)));

        if (!availablePos) {
            return null;
        }

        return availablePos;
    }

    getCompetitorsArrayFromGeneralSetting(provider: string | undefined) {
        const { currentCompany } = this.userService;
        const { competitors } = this;
        const unselectedCompetitors = this.carsSharedService.getUnselectedCompetitors();
        let generalSettingCompetitorArray: string | any[] = [];
        const { pickUpCityCode } = this.storeState.settings;
        let selectedCompetitors = new Set(competitors);
        const country = this.getCountryByPickUpLocationId(pickUpCityCode || '');
        if (country && provider) {
            generalSettingCompetitorArray = this.carsSharedService.filters.allowedVendorsPerCountry?.[country]?.[provider];
        }

        if (generalSettingCompetitorArray?.length > 0) {
            selectedCompetitors = new Set(generalSettingCompetitorArray);
        }

        if (currentCompany) {
            selectedCompetitors.add(currentCompany);
        }

        return [...selectedCompetitors].filter(item => !unselectedCompetitors.includes(item));
    }

    getCountryByPickUpLocationId(locationId: string) {
        const { countries } = this.carsSharedService.filters;
        if (!countries || !locationId) {
            return null;
        }
        return Object.keys(countries).filter(country => countries[country].filter(location => location === locationId).length)[0];
    }
}
