import { Expose, Transform } from 'class-transformer';
import {
    IsNumber, IsString, IsIn, IsBoolean, IsOptional,
} from 'class-validator';
import { get } from 'lodash';
import USER_LEVELS from '../constants/user-levels.constant';
import USER_ROLES from '../constants/user-roles.constant';

export default class UserModel {
    @Expose()
    @Transform((_, plain) => plain.fornova_user_id)
    @IsString()
    id!: string;

    @Expose()
    @IsString()
    firstName!: string;

    @Expose()
    @IsString()
    lastName!: string;

    @Expose()
    @IsString()
    email!: string;

    @Expose()
    @IsString()
    chainName!: string;

    @Expose()
    @IsString()
    chainId!: string;

    @Expose()
    @IsString()
    @IsOptional()
    chainNumber?: string;

    @Expose()
    @IsString()
    level!: USER_LEVELS;

    @Expose()
    @IsString()
    @Transform((_, plain) => plain.level)
    viewAs!: USER_LEVELS;

    @Expose()
    @IsString()
    @Transform((_, plain) => plain.levelName)
    levelName!: string;

    @Expose()
    @Transform((_, plain) => (plain.fornovaIds as number[]).map(hotelId => Number(hotelId)))
    @IsNumber({}, { each: true })
    hotelIds!: number[];

    @Expose()
    @Transform((_, plain) => Number(plain.fornovaIds[0]))
    currentHotelId!: number;

    @Expose()
    @IsString()
    token!: string;

    @Expose()
    @IsString()
    @IsOptional()
    role?: USER_ROLES;

    @Expose()
    isTestUser?: boolean;

    @Expose()
    @Transform((_, plain) => ({
        subscriptions: {
            htmlReports: plain.settings.subscribedListForMail['html-report'],
            scheduledReports: plain.settings.subscribedListForMail['scheduled-excel'],
        },
        currencies: {
            displayCurrency: plain.settings.currency || null,
        },
        intraday: {
            howManyCompetitorsRateChange: plain.settings.intraday.howManyCompetitorsRateChange,
            rateChangePercent2Alert: plain.settings.intraday.rateChangePercent2Alert,
            myCompetitorsPercentRateChange: plain.settings.intraday.myCompetitorsPercentRateChange,
            receiveAlert4HowManyCompetitorsRateChange: plain.settings.intraday.receiveAlert4HowManyCompetitorsRateChange,
            receiveAlert4CompetitorsRateChange: plain.settings.intraday.receiveAlert4CompetitorsRateChange,
            receiveAlert4MyRateChange: plain.settings.intraday.receiveAlert4MyRateChange,
            receiveEmail4MyRateChange: plain.settings.intraday.receiveEmail4MyRateChange,
        },
        alertsAndNotifications: plain.settings.alertsAndNotifications,
        alertsAndNotificationsByGroup: plain.settings.alertsAndNotificationsByGroup,
        colors: plain.settings.colors,
    }))
    settings!: {
        subscriptions: UserAPI.Subscriptions
        intraday: UserAPI.IntradaySettings
        currencies: {
            displayCurrency: string | null
        }
        alertsAndNotifications: UserAPI.AlertsAndNotifications
        alertsAndNotificationsByGroup: UserAPI.AlertsNotifcationsGroups
        colors: string[]
    };

    @Expose()
    @Transform((_, plain) => {
        const enabledFeatures: {
            [name: string]: boolean
        } = {};

        if (plain.enabled_features) {
            Object.keys(plain.enabled_features).forEach(feature => {
                enabledFeatures[feature] = plain.enabled_features[feature];
            });
        }
        return enabledFeatures;
    })
    enabledFeatures!: {
        [name: string]: boolean
    };

    @Expose()
    @Transform((_, plain) => !!plain.userOptions && !!plain.userOptions.useWalkme)
    isWalkmeEnabled!: boolean;

    @Expose()
    @Transform((_, plain) => (
        plain.applications ? {
            CI: !!plain.applications.FornovaCI,
            DI: !!plain.applications.FornovaDI,
        } : {
            CI: false,
            DI: false,
        }
    ))
    applications!: {
        CI: boolean,
        DI: boolean,
    };

    @Expose()
    @Transform((_, plain) => {
        const plainCustomerBrands = plain.customerBrands || [];
        const customerBrands = plainCustomerBrands.length > 0 ? plainCustomerBrands : [{ id: '', countries: [], locations: [] }];

        return customerBrands.reduce((acc: Record<string, { countries: string[]; locations: string[] }>, customerBrand: any) => {
            acc[customerBrand.id] = {
                countries: customerBrand.countries,
                locations: customerBrand.locations.map((location: { [key: string]: string }) => location.fn_location_id),
            };
            return acc;
        }, {});
    })
    customerBrands!: Record<string, { countries: string[]; locations: string[] }>;

    @Expose()
    @Transform((_, plain) => plain.demo_id || null)
    demoId!: string | null;

    @Expose()
    @Transform((_, plain) => !!plain.is_superadmin_token)
    isSuperadmin!: boolean;

    @Expose()
    @Transform((_, plain) => get(plain, 'userOptions.readOnly', false))
    isReadonly!: boolean;

    @Expose()
    @Transform((_, plain) => get(plain, 'userOptions.carCategoryManagement', false))
    carCategoryManagement!: boolean;

    @Expose()
    @Transform((_, plain) => get(plain, 'userOptions.generalSettings', false))
    generalSettings!: boolean;

    @Expose()
    @Transform((_, plain) => get(plain, 'userOptions.siipMapManagement', false))
    sippMapManagement!: boolean;

    @Expose()
    @Transform((_, plain) => get(plain, 'userOptions.userManagement', false))
    userManagement!: boolean;
}
