import { injectable, Inject } from 'inversify-props';
import ConfigService, { ConfigServiceS } from '@/modules/config/config.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import CarsSharedService, { CarsSharedServiceS } from '../cars/cars-shared.service';

interface ChatWidget extends HTMLElement {
    show(): void;
    hide(): void;
}

export const JiraServiceS = Symbol.for('JiraServiceS');
@injectable(JiraServiceS as unknown as string)
export default class JiraService {
    @Inject(ConfigServiceS) private configService!: ConfigService;
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(CarsSharedServiceS) protected carsSharedService!: CarsSharedService;

    chatButtonId: string = '';
    widgetButtonId: string = '';

    isChatEnabled(): boolean {
        return !!this.configService.jiraChatId && !this.carsSharedService.hideChatButton;
    }

    isWidgetFormEnabled(): boolean {
        return !!this.configService.jiraDataKey;
    }

    async initChatButton(chatButtonId: string) {
        const { isCarUser } = this.userService;
        if (!isCarUser) {
            return;
        }

        const { jiraChatId } = this.configService;
        if (!jiraChatId || !chatButtonId) {
            return;
        }
        this.chatButtonId = chatButtonId;
        await this.loadChatScript();
    }

    async initWidgetForm() {
        const { isCarUser } = this.userService;
        if (!isCarUser) {
            return;
        }

        const { jiraDataKey } = this.configService;
        if (!jiraDataKey) {
            return;
        }
        await this.loadWidgetScript();
    }

    private hideDefaultChatButton(): void {
        this.hideElementBySelector('.chat-widget-icon');
    }

    private hideChatPopup(): void {
        this.hideElementBySelector('.chat-widget-popup');
    }

    private hideElementBySelector(selector: string): void {
        const launcherInterval = setInterval(() => {
            const element = this.chatWidgetElement?.shadowRoot?.querySelector(selector);
            if (element) {
                element.setAttribute('hidden', 'true');
                clearInterval(launcherInterval);
            }
        }, 50);
    }

    private addEventBySelector(selector: string, callBack: () => void): void {
        const launcherInterval = setInterval(() => {
            const element = this.widgetIframElement?.contentDocument?.querySelector(selector);
            if (element) {
                element.addEventListener('click', () => {
                    callBack();
                });
                clearInterval(launcherInterval);
            }
        }, 50);
    }

    private showCustomChatButton(): void {
        const launcherInterval = setInterval(() => {
            const jiraChatButton = document.getElementById(this.chatButtonId);
            if (jiraChatButton) {
                jiraChatButton.classList.remove('hidden');
                clearInterval(launcherInterval);
            }
        }, 50);
    }

    private applyChatStyle() {
        const launcherInterval = setInterval(() => {
            const chatWidget = this.chatWidgetElement?.shadowRoot?.querySelector('.chat-widget-wrapper') as HTMLElement;
            if (chatWidget) {
                chatWidget.style.right = '70px';
                clearInterval(launcherInterval);
            }
        }, 50);
    }

    loadChatScript() {
        const scriptIdWidget = 'jira-chat-widget-script';

        if (document.getElementById(scriptIdWidget)) {
            return;
        }

        const { jiraChatId, jiraChatWidgetUrl } = this.configService;
        const chatWidgetScript = document.createElement('script');
        chatWidgetScript.id = scriptIdWidget;
        chatWidgetScript.src = jiraChatWidgetUrl;
        chatWidgetScript.defer = true;

        document.head.appendChild(chatWidgetScript);

        chatWidgetScript.onload = () => {
            if (!this.chatWidgetElement) {
                const chatWidgetElement = document.createElement('chat-widget');
                chatWidgetElement.setAttribute('jira-id', jiraChatId);
                chatWidgetElement.setAttribute('service-desk-id', '2');
                document.body.appendChild(chatWidgetElement);

                this.hideDefaultChatButton();
                this.showCustomChatButton();
                this.hideChatPopup();
                this.applyChatStyle();
            }
        };
    }

    loadWidgetScript() {
        const scriptIdWidget = 'jira-widget-script';

        if (document.getElementById(scriptIdWidget)) {
            return;
        }

        const { jiraDataKey, jiraWidgetBaseUrl, jiraWidgetUrl } = this.configService;
        const widgetScript = document.createElement('script');
        widgetScript.id = scriptIdWidget;
        widgetScript.setAttribute('data-jsd-embedded', '');
        widgetScript.setAttribute('data-key', jiraDataKey);
        widgetScript.setAttribute('data-base-url', jiraWidgetBaseUrl);
        widgetScript.src = jiraWidgetUrl;
        document.head.appendChild(widgetScript);

        widgetScript.onload = () => {
            const event = new Event('DOMContentLoaded');
            document.dispatchEvent(event);
            this.hideWidgetButton();
        };
    }

    openChat() {
        if (this.chatWidgetElement) {
            (this.chatWidgetElement?.shadowRoot?.querySelector('.chat-icon-button') as HTMLElement)?.click();
        }
    }

    openWidget() {
        const iframe = this.widgetIframElement;
        if (iframe) {
            const form = iframe?.contentDocument?.querySelector('#help-button') as HTMLElement;
            if (form) {
                form.click();
                iframe.style.visibility = 'visible';
                this.addEventBySelector('button[aria-label="Dismiss"]', this.hideWidgetButton.bind(this));
                this.addEventBySelector('#confirmation-container button', this.hideWidgetButton.bind(this));
            }
        }
    }

    hideWidgetButton() {
        const iframe = this.widgetIframElement;
        if (iframe) {
            iframe.style.visibility = 'hidden';
        }
    }

    get chatWidgetElement(): ChatWidget {
        return document.querySelector('chat-widget') as ChatWidget;
    }

    get widgetIframElement(): HTMLIFrameElement {
        return document.getElementById('jsd-widget') as HTMLIFrameElement;
    }
}
